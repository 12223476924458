<template>
  <detail :api="api" :nav="nav" />
</template>

<script>
import Detail from '@/views/communication/components/detail'
import Api from '@/api/communication/case'

export default {
  name: 'CaseDetail',
  components: {
    Detail
  },
  data() {
    return {
      api: Api.getDetail,
      nav: [
        { name: 'bae89f7', path: '/communication' },
        { name: '3692525', path: '/communication/case' }
      ]
    }
  },
}
</script>

<style scoped lang="less">
</style>
