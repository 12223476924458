<template>
  <div class="box">
    <slot name="header">
      <header class="box-header">
        <h4 class="box-header-title">{{ title }}</h4>
        <h5 class="box-header-subtitle">{{ subTitle }}</h5>
        <aside class="box-header-side">
          <slot name="side"></slot>
        </aside>
      </header>
    </slot>
    <div class="box-body"><slot></slot></div>
    <slot name="footer"></slot>
    <!-- <footer class="box-footer">footer</footer> -->
  </div>
</template>

<script>
export default {
  name: 'Box',
  props: {
    title: String,
    subTitle: String,
  },
  data() {
    return {}
  },
}
</script>

<style lang="less" scoped>
.box {
  background: #ffffff;
  box-shadow: 0px 2px 22px 0px rgba(218, 222, 224, 0.5);
  margin-bottom: 20px;

  &-header {
    position: relative;
    display: flex;
    align-items: center;
    min-height: 68px;
    border-bottom: 1px solid #eee;

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      width: 3px;
      height: 17px;
      background: #00a4ff;
      transform: translate(0, -50%);
    }

    &-title {
      padding: 0 10px 0 30px;
      font-size: 20px;
      color: #333333;
      font-weight: 500;
      margin: 0;
    }

    &-subtitle {
      font-size: 12px;
      color: #666666;
    }

    &-side {
      margin-left: auto;
      padding: 0 30px;
    }
  }

  &-body {
    padding: 30px;
  }
}
</style>
