<template>
  <div class="content">
    <Breadcrumb :data="navData" :is$t="true" />
    <Box v-loading="loading" class="pane">
      <div slot="header" class="pane-header">
        <h4 class="title">{{ detail.title | textFilter }}</h4>
        <p class="sub-title">
          <span>{{ $t('122cefe') }}：</span>
          <span style="margin-right: 40px">{{
            time | date('YYYY-MM-DD')
          }}</span>
          <span>{{ $t('52c01f1') }}：</span>
          <span>{{ source | textFilter }}</span>
        </p>
      </div>
      <div class="pane-body">
        <video v-if="showVideo" :src="detail.videoUrl" controls="controls" width="800"></video>
        <div v-if="isExpert" class="pane-body-content ql-editor" v-html="filteredVideoHtml"></div>
        <div v-else class="ql-editor" v-html="content"></div>
      </div>
      <div v-if="needPageNavigate" slot="footer" class="page">
        <div class="page-info" @click="jumpDetail(detail.previousPageId, 'prev')">
          <img :src="icon" class="icon icon-left" />
          <p class="page-title">{{ $t('fccf558') }}：{{ detail.previousPage }}</p>
        </div>
        <div class="page-info" @click="jumpDetail(detail.nextPageId, 'next')">
          <p class="page-title">{{ $t('93e1a9d') }}：{{ detail.nextPage }}</p>
          <img :src="icon" class="icon icon-right" />
        </div>
      </div>
    </Box>
  </div>
</template>

<script>
import Box from '@/components/Box'
import Breadcrumb from '@/components/Breadcrumb'
export default {
  components: {
    Box,
    Breadcrumb,
  },
  props: {
    api: {
      type: Function,
      default: () => {}
    },
    nav: {
      type: Array,
      default: () => []
    },
    showVideo: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      loading: true,
      siz: 15,
      detail: {},
      navData: [],
      icon: require('@/assets/communication/icon-arrow.png'),
    }
  },
  computed: {
    needPageNavigate() {
      const url = [
        '/communication/notice/detail',
        '/communication/experts/detail',
        '/communication/policy/detail',
        '/communication/news/detail',
        '/communication/ask/detail']
      return url.includes(this.$route.path)
    },
    content() {
      const { detail } = this
      // 每个接口返回值不一样
      return detail.contentHtml || detail.description || detail.content
    },
    time() {
      const { detail } = this
      return detail.issueDate || detail.releaseTime || detail.createTime
    },
    isExpert() {
      return this.$route.path === '/communication/experts/detail'
    },
    filteredVideoHtml() {
      // 如果富文本中没有video标签，且videoUrl有值，则单独渲染video
      const reg = /<video.+<\/video>/g
      const { detail } = this
      const html = detail.contentHtml || ''
      // const videoHtml = `<video src="${detail.videoUrl}" controls="controls" width="800"></video>`
      return html.replace(reg, '')
    },
    source() {
      const { detail } = this
      return detail.realSource || detail.source
    }
  },
  watch: {
    '$route.query.id'() {
      this.getDetail()
    },
    '$route.query.path'(newVal) {
      console.log(newVal)
    }
  },
  mounted() {
    if (this.isExpert) {
      this.size = 6
    } else {
      this.size = 15
    }
    this.getDetail()
  },
  methods: {
    getDetail() {
      const id = this.$route.query.id
      const { api, size } = this
      this.loading = true
      const data = { page: this.$route.query.page, size }
      console.log('datadatadata', data)
      api(id, data)
        .then((res) => {
          this.detail = res
          this.navData = [
            ...this.nav,
            {
              name: res.title,
              path: ''
            }
          ]
        })
        .catch(() => {})
        .then(() => {
          this.loading = false
        })
    },
    jumpDetail(id, type) {
      if (!id) return
      let page = Number(this.$route.query.page)
      const { firstOne, lastOne } = this.detail
      if (type === 'prev' && firstOne && page > 1) {
        // 如果是第二页的第一个，往前面翻页的话，url上面的page要减掉1
        page = page - 1
      } else if (lastOne && type === 'next') {
        page = page + 1
      }
      this.$router.replace({
        query: {
          id,
          page
        }
      })
    }
  },
}
</script>

<style scoped lang="less">
.content {
  width: 1200px;
  margin: 0 auto;
}
video {
  display: block;
  margin: 0 auto;
}
.page {
  color: #333333FF;
  display: flex;
  justify-content: space-between;
  padding: 0 30px;
  height: 74px;
  line-height: 74px;
  border-top: 1px solid #EEEEEE;
  .icon {
    width: 8px;
    height: 14px;
    &-left {
      margin-right: 20px;
    }
    &-right {
      margin-left: 20px;
      transform: rotateY(180deg);
    }
  }
  &-info {
    display: flex;
    align-items: center;
    cursor: pointer;
    &:first-child {
      text-align: left;
    }
    &:last-child {
      justify-content: flex-end;
      text-align: right;
    }
  }
  &-title {
    width: 500px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
.pane-header {
  padding: 30px;
  border-bottom: 1px solid #eee;

  .title {
    font-size: 20px;
    color: #333333;
    line-height: 30px;
    font-weight: 500;
    text-align: center;
    padding-bottom: 30px;
  }

  .sub-title {
    font-size: 13px;
    color: #999999;
    text-align: center;
  }
}

.pane-body {
  font-size: 14px;
  color: #999999;
  line-height: 1.5;
  p {
    padding-bottom: 30px;
  }
}
</style>
